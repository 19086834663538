import { render, staticRenderFns } from "./Herbal.vue?vue&type=template&id=72cfe993&scoped=true&"
import script from "./Herbal.vue?vue&type=script&lang=js&"
export * from "./Herbal.vue?vue&type=script&lang=js&"
import style0 from "./Herbal.vue?vue&type=style&index=0&id=72cfe993&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72cfe993",
  null
  
)

export default component.exports